import React from 'react';

const VerifiedBadge = () => (
  <div className="verified-badge bg-green-500 text-white px-4 py-2 rounded-full">
    Verified
  </div>
);


export default VerifiedBadge;
